import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

export default function GoogleMap({ data }) {
  const options = data.options.siteSettings.acfOptions;
  const icon = options.mapMarker.localFile.publicURL;
  const markers = [{ lat: options.map.latitude, lng: options.map.longitude }];

  console.log(data);
  // useEffect(() => {
  //   if(typeof window !== 'undefined'){
  //     // const map = document.querySelector('.contact-map')
  //     // initMap(map)
  //   }
  // }, [])

  const Map = ({ onClick, onIdle, children, style, ...options }) => {
    const ref = React.useRef(null);
    const [map, setMap] = React.useState();

    React.useEffect(() => {
      if (ref.current && !map) {
        setMap(new window.google.maps.Map(ref.current, {}));
      }
    }, [ref, map]);

    React.useEffect(() => {
      if (map) {
        map.setOptions(options);
      }
    }, [map, options]);

    // React.useEffect(() => {
    //   if (map) {
    //     ["click", "idle"].forEach((eventName) =>
    //       window.google.maps.event.clearListeners(map, eventName)
    //     );

    //     if (onClick) {
    //       map.addListener("click", onClick);
    //     }

    //     if (onIdle) {
    //       map.addListener("idle", () => onIdle(map));
    //     }
    //   }
    // }, [map, onClick, onIdle]);

    return (
      <>
        <div ref={ref} style={style} />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { map });
          }
        })}
      </>
    );
  };

  const Marker = (options, icon) => {
    const [marker, setMarker] = React.useState();
    const contentRef = React.useRef(null);

    React.useEffect(() => {
      if (!marker) {
        setMarker(new window.google.maps.Marker({ icon: icon }));
      }

      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);

    React.useEffect(() => {
      if (marker) {
        // const infowindow = new window.google.maps.InfoWindow({
        //   content: `daver`
        // });
        marker.setOptions(options);

        // marker.addListener("click", () => {
        //   infowindow.open({
        //     anchor: marker,
        //     shouldFocus: false
        //   });
        // });
      }
    }, [marker, options]);

    return null;
  };

  return (
    <Wrapper apiKey={process.env.GATSBY_GOOGLEMAPS_API_KEY}>
      <Map
        center={{
          lat: options.map.latitude,
          lng: options.map.longitude,
        }}
        zoom={16}
        style={{ flexGrow: "1", height: "100%" }}
        disableDefaultUI={true}
        mapTypeControl={false}
        scaleControl={false}
        zoomControl={false}
        styles={[
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36,
              },
              {
                color: "#000000",
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#000000",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "all",
            stylers: [
              {
                visibility: "on",
              },
              {
                saturation: "0",
              },
              {
                lightness: "0",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on",
              },
              {
                saturation: "0",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#898989",
              },
            ],
          },
          {
            featureType: "transit.station.rail",
            elementType: "labels.icon",
            stylers: [
              {
                saturation: "-39",
              },
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#000000",
              },
              {
                lightness: 17,
              },
            ],
          },
        ]}
      >
        {markers.map((marker) => {
          return <Marker position={marker} icon={icon} />;
        })}
      </Map>
    </Wrapper>
  );
}
