import React, { useEffect, useState } from "react";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [thankYouMessage, setThankYouMessage] = useState("");

  const resetFormAndShowThankYou = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
    setThankYouMessage("Thanks, we will respond to your message soon.");
    setTimeout(() => {
      setThankYouMessage("");
    }, 10000);
  };

  // const formData = {
  //   name: "Test Name",
  //   email: "mike.lawton86@gmail.com",
  //   phone: "07967604288",
  //   subject: "Test Subject",
  //   message: "This is a test message",
  // };

  const formData = {
    name,
    email,
    phone,
    subject,
    message,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/api/sendMail", {
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then(() => resetFormAndShowThankYou())
      .catch((error) => alert(error));
  };

  return (
    <form name="contact" method="POST" onSubmit={handleSubmit}>
      <ul className="contact-form-wrapper">
        <li className="col-2">
          <label className="label" htmlFor="input_1_1">
            Name
            <span className="required">
              <span className="required required_asterisk">*</span>
            </span>
          </label>
          <div className="ginput_container ginput_container_text">
            <input
              onChange={(e) => setName(e.target.value)}
              name="input_1"
              id="input_1_1"
              type="text"
              value={name}
              className="medium"
              placeholder="Harry Gate"
              aria-required="true"
              aria-invalid="false"
            />
          </div>
        </li>
        <li className="col-2">
          <label className="label" htmlFor="input_1_2">
            Email
            <span className="required">
              <span className="required required_asterisk">*</span>
            </span>
          </label>
          <div className="ginput_container ginput_container_email">
            <input
              onChange={(e) => setEmail(e.target.value)}
              name="input_2"
              id="input_1_2"
              type="text"
              value={email}
              className="medium"
              placeholder="you@youremail.com"
              aria-required="true"
              aria-invalid="false"
            />
          </div>
        </li>
        <li className="col-2">
          <label className="label" htmlFor="input_1_3">
            Phone
            <span className="required">
              <span className="required required_asterisk">*</span>
            </span>
          </label>
          <div className="ginput_container ginput_container_phone">
            <input
              onChange={(e) => setPhone(e.target.value)}
              name="input_3"
              id="input_1_3"
              type="text"
              value={phone}
              className="medium"
              placeholder="01234 567891"
              aria-required="true"
              aria-invalid="false"
            />
          </div>
        </li>
        <li className="col-2">
          <label className="label" htmlFor="input_1_4">
            Subject
          </label>
          <div className="ginput_container ginput_container_text">
            <input
              onChange={(e) => setSubject(e.target.value)}
              name="input_4"
              id="input_1_4"
              type="text"
              value={subject}
              className="medium"
              placeholder="What is it regarding?"
              aria-invalid="false"
            />
          </div>
        </li>
        <li className="full-width">
          <label className="label" htmlFor="input_1_5">
            MESSAGE
            <span className="required">
              <span className="required required_asterisk">*</span>
            </span>
          </label>
          <div className="ginput_container ginput_container_textarea">
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              name="input_5"
              id="input_1_5"
              className="textarea medium"
              placeholder="Please tell us more"
              aria-required="true"
              aria-invalid="false"
              rows="10"
              cols="50"
            ></textarea>
          </div>
        </li>
      </ul>
      <div className="gform_footer top_label">
        <input
          type="submit"
          id="gform_submit_button_1"
          className="gform_button button"
          value="Submit"
        />
      </div>
      {thankYouMessage}
    </form>
  );
}
